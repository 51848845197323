import React from "react"
import Img from "gatsby-image"

const HorizontalRow = ({ image, title, number }) => {
  if (number === 0) {
    return(
      <div className="wedding--image-horizontal" data-sal="slide-up" data-sal-easing="ease" style={{"--sal-duration": "2s", "--sal-delay": "750ms"}}>
        <Img
          className="wedding--image-horizontal"
          fluid={image.fluid}
          backgroundColor="#FBFBFB"
          alt={`Boda ${title}`}
        />
      </div>
    )
  } else {
    return(
      <Img
        className="wedding--image-horizontal"
        fluid={image.fluid}
        alt={`Boda ${title}`}
        backgroundColor="#FBFBFB"
      />
      )
  }
}

export default HorizontalRow
