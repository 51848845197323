import React from 'react'
import {Link} from 'gatsby'
import {window} from 'browser-monads'
import smoothscroll from 'smoothscroll-polyfill'
import SEO from "../components/seo"
import HorizontalRow from '../components/horizontalRow'
import VerticalRow from '../components/verticalRow'
import arrow from '../images/agba-arrow.svg'

function Wedding (props) {
  const
    {wedding} = props.pageContext,
    {title, subtitle, planner, plannerUrl, date, venue, rowsGallery } = wedding;

  const scrollTop = () => {
    window.__forceSmoothScrollPolyfill__ = true;
    smoothscroll.polyfill()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return(
    <React.Fragment>
      <SEO title={`${title} Wedding`}/>
      <div id="wedding">
        <h1 data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "2s", "--sal-delay": "1s"}}>{title}</h1>
        <p className="wedding--subtitle" data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "1.5s", "--sal-delay": "550ms"}}>{subtitle}</p>
        {
          rowsGallery.map((row, key) => {
            const images = row.imagesGallery.map(image => image.asset)
            return (
              images.length === 1 ?
              <HorizontalRow key={key} number={key} title={title} image={images[0]}/>
              :
              <VerticalRow key={key} number={key} title={title} images={images}/>
            )
          })
        }

        <div className="wedding--scroll-to-top">
          <div onClick={scrollTop} onKeyDown={scrollTop}>
            <img src={arrow} alt="scroll to top"/>
          </div>
        </div>

        <div className="wedding--info-container">
          <div>
            <p data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="550">Wedding planner: <a href={plannerUrl} target="__blank">{planner}</a></p>
            <p data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="550">Venue: {venue}</p>
            <p data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="550">Date: {date}</p>
          </div>
          <div className="wedding--link">
          <p data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="550"><Link to="/">Back to weddings</Link></p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Wedding
